<template>
  <div class="vx-row">
    <div class="vx-col md:w-3/4">

      <!-- PDF Generator -->

        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="RefOrd"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="90%"

          ref="html2Pdf"
        >
          <section slot="pdf-content" class="pl-12">
            <div class="vx-row mt-5 pdf-item">
              <div class="vx-col md:w-1/2">
              </div>
              <div class="vx-col md:w-1/2 text-right">
                Date: {{DateOrd | moment("calendar", "July 10 2011") }}
              </div>
            </div>
            <div class="vx-row mt-5 pdf-item">
              <div class="vx-col w-full text-center">
                <span  style="font-size: 20px; font-weight: bold; text-decoration: underline">ORDONNANCE LUNETTE</span>
              </div>
            </div>
            <div class="vx-row mt-5 pdf-item">
              <div class="vx-col md:w-1/2">
                N° Ordo: {{RefOrd}}
              </div>
            </div>
            <div class="vx-row mt-5 pdf-item">
              <div class="vx-col md:w-1/2 text-center">
                Patient: {{client.Nom ? client.Nom : ''}} {{client.Prenoms ? client.Prenoms : ''}}
              </div>
            </div>
            <div class="vx-row mt-5 pdf-item">
              <div class="vx-col w-full">
                <table class="w-full"  cellpadding="0" cellspacing="0">
                  <thead>
                    <tr>
                      <th style="border-bottom-width: 0px; border-left-width: 0px; border-top-width: 0px;border-right-width: 0px;" colspan="1"></th>
                      <th class="text-center" style="border-bottom-width: 0px; border-left-width: 0px; border-top-width: 0px" colspan="4">

                        <div class="vx-col md-full">90°</div>
                        <!-- <div class="vx-col md-full text-center">
                          <img class="mr-0" src="@/assets/images/logo/accolade.svg" alt="groupe-logo" >
                        </div> -->
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2">180°</div>
                          <div class="vx-col md:w-1/2">0°</div>
                        </div>
                        <span style="font-size: 30px">OD</span>

                      </th>
                      <th class="text-center" style="border-bottom-width: 0px;border-top-width: 0px; border-right-width: 0px" colspan="4">
                        <div class="vx-col md-full">90°</div>
                        <div class="vx-row">
                          <div class="vx-col md:w-1/2">180°</div>
                          <div class="vx-col md:w-1/2">0°</div>
                        </div>
                        <span style="font-size: 30px">OG</span>
                      </th>
                    </tr>
                    <tr>
                      <th style="border: none"></th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;">Sphère</th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;">Cyl</th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;">Axe</th>
                      <th style="border-bottom-width: 0px;">Av</th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;">Sphère</th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;">Cyl</th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;">Axe</th>
                      <th style="border-bottom-width: 0px;">Av</th>
                    </tr>
                    <tr>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;">VISION DE LOIN</th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            <span v-if="parseFloat(OdVlSph) >= 0">
                              +{{ formatterNumber(parseFloat(OdVlSph))}}
                            </span>
                            <span v-else>
                              {{formatterNumber(parseFloat(OdVlSph))}}
                            </span>
                          </div>
                        </div>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            <span v-if="parseFloat(OdVlCyl) >= 0">
                              +{{ formatterNumber(parseFloat(OdVlCyl))}}
                            </span>
                            <span v-else>
                              {{formatterNumber(parseFloat(OdVlCyl))}}
                            </span>
                          </div>
                        </div>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            {{ OdVlAxe ? `${parseFloat(OdVlAxe)}°` : ''}}
                          </div>
                        </div>
                      </th>
                      <th style="border-bottom-width: 0px;">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            {{ OdVlAv ? `${parseFloat(OdVlAv)}/10` : ''}}
                          </div>
                        </div>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            <span v-if="parseFloat(OgVlSph) >= 0">
                              +{{ formatterNumber(parseFloat(OgVlSph))}}
                            </span>
                            <span v-else>
                              {{formatterNumber(parseFloat(OgVlSph))}}
                            </span>
                          </div>
                        </div>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            <span v-if="parseFloat(OgVlCyl) >= 0">
                              +{{ formatterNumber(parseFloat(OdVlCyl))}}
                            </span>
                            <span v-else>
                              {{formatterNumber(parseFloat(OgVlCyl))}}
                            </span>
                          </div>
                        </div>
                      </th>
                      <th style="border-bottom-width: 0px; border-right-width: 0px;">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            {{ OgVlAxe ? `${parseFloat(OgVlAxe)}°` : ''}}
                          </div>
                        </div>
                      </th>
                      <th style="border-bottom-width: 0px;">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            {{ OgVlAv ? `${parseFloat(OgVlAv)}/10` : ''}}
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">
                        Addition:
                      </th>
                      <th colspan="4" style="border-bottom-width: 0px; border-left-width: 0px">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            <span v-if="parseFloat(OdAddition) >= 0">
                              +{{ formatterNumber(parseFloat(OdAddition))}}
                            </span>
                            <span v-else>
                              {{formatterNumber(parseFloat(OdAddition))}}
                            </span>
                          </div>
                        </div>
                      </th>
                      <th colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">
                        Addition:
                      </th>
                      <th colspan="3" style="border-bottom-width: 0px;border-left-width: 0px">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            <span v-if="parseFloat(OgAddition) >= 0">
                              +{{ formatterNumber(parseFloat(OgAddition))}}
                            </span>
                            <span v-else>
                              {{formatterNumber(parseFloat(OgAddition))}}
                            </span>
                          </div>
                        </div>
                      </th>

                    </tr>
                    <tr>
                      <th style="border-right-width: 0px;">VISION DE PRES</th>
                      <th style="border-right-width: 0px;">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            <span v-if="parseFloat(OdVpSph) >= 0">
                              +{{ formatterNumber(parseFloat(OdVpSph))}}
                            </span>
                            <span v-else>
                              {{formatterNumber(parseFloat(OdVpSph))}}
                            </span>
                          </div>
                        </div>
                      </th>
                      <th style="border-right-width: 0px;">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            <span v-if="parseFloat(OdVpCyl) >= 0">
                              +{{ formatterNumber(parseFloat(OdVpCyl))}}
                            </span>
                            <span v-else>
                              {{formatterNumber(parseFloat(OdVpCyl))}}
                            </span>
                          </div>
                        </div>
                      </th>
                      <th style="border-right-width: 0px;">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            {{ OdVpAxe ? `${parseFloat(OdVpAxe)}°` : ''}}
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            {{ OdVpAv ? `${parseFloat(OdVpAv)}/10` : ''}}
                          </div>
                        </div>
                      </th>
                      <th style="border-right-width: 0px;">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            <span v-if="parseFloat(OgVpSph) >= 0">
                              +{{ formatterNumber(parseFloat(OgVpSph))}}
                            </span>
                            <span v-else>
                              {{formatterNumber(parseFloat(OgVpSph))}}
                            </span>
                          </div>
                        </div>
                      </th>
                      <th style="border-right-width: 0px;">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            <span v-if="parseFloat(OgVpCyl) >= 0">
                              +{{ formatterNumber(parseFloat(OgVpCyl))}}
                            </span>
                            <span v-else>
                              {{formatterNumber(parseFloat(OgVpCyl))}}
                            </span>
                          </div>
                        </div>
                      </th>
                      <th style="border-right-width: 0px;">
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            {{ OgVpAxe ? `${parseFloat(OgVpAxe)}°` : ''}}
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="vx-row">
                          <div class="vx-col w-full text-center">
                            {{ OdVpAv ? `${parseFloat(OdVpAv)}/10` : ''}}
                          </div>
                        </div>
                      </th>
                    </tr>
                </thead>
                </table>
              </div>
            </div>
            <div class="vx-row mt-5 pdf-item">
              <div class="vx-col md:w-1/2 mt-4">
                <h3>TYPE DE VERRE:</h3>
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 mt-4">
                    <p>Simple Foyer</p>
                  </div>
                  <div class="vx-col md:w-1/2 mt-4">
                    <vs-radio disabled v-model="typeVerre" vs-value="Simple foyer"></vs-radio>
                  </div>
                  <div class="vx-col md:w-1/2 mt-4">
                    <p>Double Foyer</p>
                  </div>
                  <div class="vx-col md:w-1/2 mt-4">
                    <vs-radio disabled v-model="typeVerre" vs-value="Double foyer"></vs-radio>
                  </div>
                  <div class="vx-col md:w-1/2 mt-4">
                    <p>Progressif</p>
                  </div>
                  <div class="vx-col md:w-1/2 mt-4">
                    <vs-radio disabled v-model="typeVerre" vs-value="Progessif"></vs-radio>
                  </div>
                </div>
              </div>
              <div class="vx-col md:w-1/2">
                <h3>OPTION:</h3>
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 mt-4">
                    <p>Photochromique</p>
                  </div>
                  <div class="vx-col md:w-1/2 mt-4">
                    <vs-checkbox disabled label="PhotoChrom" v-model="PhotoChrom" class="w-full" ></vs-checkbox>
                  </div>
                  <div class="vx-col md:w-1/2 mt-4">
                    <p>Anti-Reflet</p>
                  </div>
                  <div class="vx-col md:w-1/2 mt-4">
                    <vs-checkbox disabled label="AntiReflet" v-model="AntiReflet" class="w-full" ></vs-checkbox>
                  </div>
                  <div class="vx-col md:w-1/2 mt-4">
                    <p>Teinte</p>
                  </div>
                  <div class="vx-col md:w-1/2 mt-4">
                    {{Teinte}}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </vue-html2pdf>

        <!-- PDF Generator end -->
      <vx-card>
        <div class="vx-row mt-5">
          <div class="vx-col md:w-1/2">
          </div>
          <div class="vx-col md:w-1/2 text-right">
            Date: {{DateOrd | moment("calendar", "July 10 2011") }}
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col w-full text-center">
            <span  style="font-size: 20px; font-weight: bold; text-decoration: underline">ORDONNANCE LUNETTE</span>
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col md:w-1/2">
            N° Ordo: {{RefOrd}}
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col md:w-1/2 text-center">
            Patient: {{client.Nom}} {{client.Prenoms}}
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col w-full">
            <table class="w-full"  cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th style="border-bottom-width: 0px; border-left-width: 0px; border-top-width: 0px;border-right-width: 0px;" colspan="1"></th>
                  <th class="text-center" style="border-bottom-width: 0px; border-left-width: 0px; border-top-width: 0px" colspan="4">

                    <div class="vx-col md-full">90°</div>
                    <!-- <div class="vx-col md-full text-center">
                      <img class="mr-0" src="@/assets/images/logo/accolade.svg" alt="groupe-logo" >
                    </div> -->
                    <div class="vx-row">
                      <div class="vx-col md:w-1/2">180°</div>
                      <div class="vx-col md:w-1/2">0°</div>
                    </div>
                    <span style="font-size: 30px">OD</span>

                  </th>
                  <th class="text-center" style="border-bottom-width: 0px;border-top-width: 0px; border-right-width: 0px" colspan="4">
                    <div class="vx-col md-full">90°</div>
                    <div class="vx-row">
                      <div class="vx-col md:w-1/2">180°</div>
                      <div class="vx-col md:w-1/2">0°</div>
                    </div>
                    <span style="font-size: 30px">OG</span>
                  </th>
                </tr>
                <tr>
                  <th style="border: none"></th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">Sphère</th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">Cyl</th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">Axe</th>
                  <th style="border-bottom-width: 0px;">Av</th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">Sphère</th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">Cyl</th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">Axe</th>
                  <th style="border-bottom-width: 0px;">Av</th>
                </tr>
                <tr>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">VISION DE LOIN</th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        <span v-if="parseFloat(OdVlSph) >= 0">
                          +{{ formatterNumber(parseFloat(OdVlSph))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OdVlSph))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        <span v-if="parseFloat(OdVlCyl) >= 0">
                          +{{ formatterNumber(parseFloat(OdVlCyl))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OdVlCyl))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        {{ OdVlAxe ? `${parseFloat(OdVlAxe)}°` : ''}}
                      </div>
                    </div>
                  </th>
                  <th style="border-bottom-width: 0px;">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        {{ OdVlAv ? `${parseFloat(OdVlAv)}/10` : ''}}
                      </div>
                    </div>
                  </th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        <span v-if="parseFloat(OgVlSph) >= 0">
                          +{{ formatterNumber(parseFloat(OgVlSph))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OgVlSph))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        <span v-if="parseFloat(OgVlCyl) >= 0">
                          +{{ formatterNumber(parseFloat(OdVlCyl))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OgVlCyl))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-bottom-width: 0px; border-right-width: 0px;">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        {{ OgVlAxe ? `${parseFloat(OgVlAxe)}°` : ''}}
                      </div>
                    </div>
                  </th>
                  <th style="border-bottom-width: 0px;">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        {{ OgVlAv ? `${parseFloat(OgVlAv)}/10` : ''}}
                      </div>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">
                    Addition:
                  </th>
                  <th colspan="4" style="border-bottom-width: 0px; border-left-width: 0px">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        <span v-if="parseFloat(OdAddition) >= 0">
                          +{{ formatterNumber(parseFloat(OdAddition))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OdAddition))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th colspan="1" style="border-bottom-width: 0px; border-right-width: 0px;">
                    Addition:
                  </th>
                  <th colspan="3" style="border-bottom-width: 0px;border-left-width: 0px">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        <span v-if="parseFloat(OgAddition) >= 0">
                          +{{ formatterNumber(parseFloat(OgAddition))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OgAddition))}}
                        </span>
                      </div>
                    </div>
                  </th>

                </tr>
                <tr>
                  <th style="border-right-width: 0px;">VISION DE PRES</th>
                  <th style="border-right-width: 0px;">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        <span v-if="parseFloat(OdVpSph) >= 0">
                          +{{ formatterNumber(parseFloat(OdVpSph))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OdVpSph))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-right-width: 0px;">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        <span v-if="parseFloat(OdVpCyl) >= 0">
                          +{{ formatterNumber(parseFloat(OdVpCyl))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OdVpCyl))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-right-width: 0px;">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        {{ OdVpAxe ? `${parseFloat(OdVpAxe)}°` : ''}}
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        {{ OdVpAv ? `${parseFloat(OdVpAv)}/10` : '' }}
                      </div>
                    </div>
                  </th>
                  <th style="border-right-width: 0px;">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        <span v-if="parseFloat(OgVpSph) >= 0">
                          +{{ formatterNumber(parseFloat(OgVpSph))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OgVpSph))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-right-width: 0px;">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        <span v-if="parseFloat(OgVpCyl) >= 0">
                          +{{ formatterNumber(parseFloat(OgVpCyl))}}
                        </span>
                        <span v-else>
                          {{formatterNumber(parseFloat(OgVpCyl))}}
                        </span>
                      </div>
                    </div>
                  </th>
                  <th style="border-right-width: 0px;">
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        {{ OgVpAxe ? `${parseFloat(OgVpAxe)}°` : ''}}
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="vx-row">
                      <div class="vx-col w-full text-center">
                        {{ OdVpAv ? `${parseFloat(OdVpAv)}/10` : '' }}
                      </div>
                    </div>
                  </th>
                </tr>
            </thead>
            </table>
          </div>
        </div>
        <div class="vx-row mt-5">
          <div class="vx-col md:w-1/2 mt-4">
            <h3>TYPE DE VERRE:</h3>
            <div class="vx-row">
              <div class="vx-col md:w-1/2 mt-4">
                <p>Simple Foyer</p>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <vs-radio disabled v-model="typeVerre" vs-value="Simple foyer"></vs-radio>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <p>Double Foyer</p>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <vs-radio disabled v-model="typeVerre" vs-value="Double foyer"></vs-radio>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <p>Progressif</p>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <vs-radio disabled v-model="typeVerre" vs-value="Progessif"></vs-radio>
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/2">
            <h3>OPTION:</h3>
            <div class="vx-row">
              <div class="vx-col md:w-1/2 mt-4">
                <p>Photochromique</p>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <vs-checkbox disabled label="PhotoChrom" v-model="PhotoChrom" class="w-full" ></vs-checkbox>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <p>Anti-Reflet</p>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <vs-checkbox disabled label="AntiReflet" v-model="AntiReflet" class="w-full" ></vs-checkbox>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                <p>Teinte</p>
              </div>
              <div class="vx-col md:w-1/2 mt-4">
                {{Teinte}}
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col md:w-1/4">
      <vx-card>
        <vs-select disabled="true" label="Prescripteurs" autocomplete class="selectExample w-full select-large mt-4" v-model="Prescripteur">
          <vs-select-item class="w-full" :key="index" :value="item._id" :text="`${item.NomPresc} ${item.PrenomPresc}`" v-for="(item,index) in prescripteurs" />
        </vs-select>

        <div class="mt-4">
          <vs-button @click="generateReport()" color="primary" class="w-full" icon-pack="feather" icon="icon-printer" >Imprimmer</vs-button>
        </div>

      </vx-card>
    </div>

  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: {
    flatPickr,
    VueHtml2pdf
  },
  data () {
    return {
      IdUser: null,
      client: null,
      IdClient: null,
      RefOrd: '',
      Prescripteur: null,
      DateOrd: null,
      OdVpSph: '',
      OdVpCyl: '',
      OdVpAxe: '',
      OdVpAv: '',
      OdVlSph: '',
      OdVlCyl: '',
      OdVlAxe: '',
      OdVlAv: '',
      OdAddition: '',
      OgVlSph: '',
      OgVlCyl: '',
      OgVlAxe: '',
      OgVlAv: '',
      OgVpSph: '',
      OgVpCyl: '',
      OgVpAxe: '',
      OgVpAv: '',
      OgAddition: '',
      PhotoChrom: false,
      AntiReflet: false,
      typeVerre: '',
      Teinte: '',
      OdVlSphEdit: false,
      OdVlCylEdit: false,
      OdVlAxeEdit: false,
      OdVlAvEdit: false,
      OgVlSphEdit: false,
      OgVlCylEdit: false,
      OgVlAxeEdit: false,
      OgVlAvEdit: false,
      OdAdditionEdit: false,
      OgAdditionEdit: false,
      OdVpSphEdit: false,
      OdVpCylEdit: false,
      OdVpAxeEdit: false,
      OdVpAvEdit: false,
      OgVpSphEdit: false,
      OgVpCylEdit: false,
      OgVpAxeEdit: false,
      OgVpAvEdit: false

    }
  },
  computed: {
    prescripteurs () {
      return this.$store.state.prescripteur.prescripteurs
    }
  },
  methods: {
    generateReport () {

      this.$vs.loading({
        type: 'sound'
      })

      setTimeout(() => {
        this.$vs.loading.close()
      }, 10000)

      this.$refs.html2Pdf.generatePdf()
        .catch(err => {
          console.error(err)
        })


    },

    OdVlSphEditFunction () {
      this.OdVlSphEdit = !this.OdVlSphEdit
    },
    OgVlSphEditFunction () {
      this.OgVlSphEdit = !this.OgVlSphEdit
    },
    OdVlCylEditFunction () {
      this.OdVlCylEdit = !this.OdVlCylEdit
    },
    OgVlCylEditFunction () {
      this.OgVlCylEdit = !this.OgVlCylEdit
    },
    OdVlAxeEditFunction () {
      this.OdVlAxeEdit = !this.OdVlAxeEdit
    },
    OgVlAxeEditFunction () {
      this.OgVlAxeEdit = !this.OgVlAxeEdit
    },
    OdVlAvEditFunction () {
      this.OdVlAvEdit = !this.OdVlAvEdit
    },
    OgVlAvEditFunction () {
      this.OgVlAvEdit = !this.OgVlAvEdit
    },
    OgAdditionEditFunction () {
      this.OgAdditionEdit = !this.OgAdditionEdit
      this.OgVpSph = parseFloat(this.OgVlSph) + parseFloat(this.OgAddition)
      this.OgVpCyl = parseFloat(this.OgVlCyl) + parseFloat(this.OgAddition)


    },
    OdAdditionEditFunction () {
      this.OdAdditionEdit = !this.OdAdditionEdit

      this.OdVpSph = parseFloat(this.OdVlSph) + parseFloat(this.OdAddition)
      this.OdVpCyl = parseFloat(this.OdVlCyl) + parseFloat(this.OdAddition)

    },
    OdVpSphEditFunction () {
      this.OdVpSphEdit = !this.OdVpSphEdit
    },
    OdVpCylEditFunction () {
      this.OdVpCylEdit = !this.OdVpCylEdit
    },
    OdVpAxeEditFunction () {
      this.OdVpAxeEdit = !this.OdVpAxeEdit
    },
    OdVpAvEditFunction () {
      this.OdVpAvEdit = !this.OdVpAvEdit
    },
    OgVpSphEditFunction () {
      this.OgVpSphEdit = !this.OgVpSphEdit
    },
    OgVpCylEditFunction () {
      this.OgVpCylEdit = !this.OgVpCylEdit
    },
    OgVpAxeEditFunction () {
      this.OgVpAxeEdit = !this.OgVpAxeEdit
    },
    OgVpAvEditFunction () {
      this.OgVpAvEdit = !this.OgVpAvEdit
    },

    formatterNumber (nbre) {
      return nbre ? nbre.toFixed(2) : ''
    },

    getPrescripteurs () {
      this.$store.dispatch('prescripteur/getPrescripteurs')
        .then(() => {

        })
        .catch(err => { console.log(err) })
    },
    getClientById () {
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('client/getClientById', this.$route.params.clientId)
        .then((resp) => {
          this.client = resp.data
          this.IdClient = this.client._id

          const ordonnance = this.client.ordonnances.find((o) => o.RefOrd === this.$route.params.refOrd)

          this.IdUser = ordonnance.IdUser
          this.RefOrd = ordonnance.RefOrd
          this.Prescripteur = ordonnance.Prescripteur
          this.DateOrd = ordonnance.DateOrd
          this.OdVpSph = ordonnance.OdVpSph
          this.OdVpCyl = ordonnance.OdVpCyl
          this.OdVpAxe = ordonnance.OdVpAxe
          this.OdVpAv = ordonnance.OdVpAv
          this.OdVlSph = ordonnance.OdVlSph
          this.OdVlCyl = ordonnance.OdVlCyl
          this.OdVlAxe = ordonnance.OdVlAxe
          this.OdVlAv = ordonnance.OdVlAv
          this.OdAddition = ordonnance.OdAddition
          this.OgVlSph = ordonnance.OgVlSph
          this.OgVlCyl = ordonnance.OgVlCyl
          this.OgVlAxe = ordonnance.OgVlAxe
          this.OgVlAv = ordonnance.OgVlAv
          this.OgVpSph = ordonnance.OgVpSph
          this.OgVpCyl = ordonnance.OgVpCyl
          this.OgVpAxe = ordonnance.OgVpAxe
          this.OgVpAv = ordonnance.OgVpAv
          this.OgAddition = ordonnance.OgAddition
          this.PhotoChrom = ordonnance.PhotoChrom
          this.AntiReflet = ordonnance.AntiReflet
          this.typeVerre = ordonnance.typeVerre
          this.Teinte = ordonnance.Teinte

          this.$vs.loading.close()

        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    }
  },
  created () {
    this.getClientById()
    this.getPrescripteurs()
  }
}
</script>
<style scoped>
.not-data-table{
  display: none;
}

/* table {
  border:   1px solid black;
} */
th, td {
  border: 1px solid black;
  padding: 15px;
}

.no-padding {
  padding: 0px !important;
}

.prescription {
  display: inline-block;
}
</style>
